html * {
  font-family: 'Pretendard';
  box-sizing: border-box;
  word-break: keep-all;
}
html {
  font-size: 10px;
}

@media screen and (max-width: 1240px) {
  .desktop {
    display: none !important;
  }
}
@media screen and (min-width: 1241px) {
  .mobile {
    display: none !important;
  }
}

img[src$=".gif"], img[src$=".png"] {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  backface-visibility: hidden;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}